import React, { useContext, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./styles/main.scss";
import Loading from "./components/Loading";
import { UserProvider, UserContext } from "./components/UserProvider";
import { BriefsProvider } from "./components/BriefsProvider";

export const BASE_URL = "https://unbox.1000headsdev.com/api";
export const isDev = true;

const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Ideas = lazy(() => import("./pages/Ideas"));
const Briefs = lazy(() => import("./pages/Briefs"));
const Peoples = lazy(() => import("./pages/Peoples"));
const Brief = lazy(() => import("./pages/Brief"));
const Idea = lazy(() => import("./pages/Idea"));
const People = lazy(() => import("./pages/People"));

function PrivateRoute({ children }) {
    const { user, loading } = useContext(UserContext);

    if (loading) {
        return <Loading />;
    }

    return user ? children : <Navigate to="/login" />;
}

export default function App() {
    const privateRoute = (element) => <PrivateRoute>{element}</PrivateRoute>;

    return (
        <Router basename={process.env.PUBLIC_URL}>
            <UserProvider>
                <Suspense fallback={<Loading />}>
                    <Routes>
                        {/* Public Route - Login */}
                        <Route path="/login" element={<Login />} />
                        {/* Protected Routes */}
                        <Route
                            path="/*"
                            element={
                                <BriefsProvider>
                                    <Routes>
                                        <Route path="/" element={privateRoute(<Briefs />)} />
                                        <Route path="/ideas" element={privateRoute(<Ideas />)} />
                                        <Route path="/briefs" element={privateRoute(<Briefs />)} />
                                        <Route path="/people" element={privateRoute(<Peoples />)} />
                                        <Route path="/people/:id" element={privateRoute(<People />)} />
                                        <Route path="/briefs/:id" element={privateRoute(<Brief />)} />
                                        <Route path="/ideas/:id" element={privateRoute(<Idea />)} />
                                        <Route path="/briefs/:id/idea" element={privateRoute(<Idea isDraft />)} />
                                    </Routes>
                                </BriefsProvider>
                            }
                        />
                    </Routes>
                </Suspense>
            </UserProvider>
        </Router>
    );
}
