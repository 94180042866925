import React from "react";

const Loading = () => {
    return (
        <div id="loading-screen">
            <img className="loading" src="/assets/loading.gif" alt="Loading..." />
        </div>
    );
};

export default Loading;
